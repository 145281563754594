const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"acumin-pro-extra-condensed",serif',

  primaryColor: '#c20f2f',
  fontColor: '#534741',
  bgColor: '#ebebeb',
  darkBrownColor : '#534741',
  lightBrownColor : '#c7b299',

  ...overloading
}

export default variables;